import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import gestion_reparation from "../../Assets/Projects/projet_gestion_reparation.png";
import table_de_tri from "../../Assets/Projects/projet_table_de_tri.png";

function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          Mes <strong className="purple">Projets </strong>
        </h1>
        <p style={{ color: "white" }}>
          Ici sont les projets que j'ai pu réaliser.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={gestion_reparation}
              isBlog={false}
              title="Gestion Réparation"
              description="Ceci est un projet personnel réalisé avec JavaFX qui est à destination d'une entreprise de maintenance informatique afin de répertorier et d'avoir un suivi sur toutes les réparations."
              ghLink="https://github.com/NathanVNT/Gestion_Reparation"
              
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={table_de_tri}
              isBlog={false}
              title="Table de tri connectée"
              description="Ce projet est mon projet de fin d'année de BTS, c'est une application web codé avec React en TypeScript qui a pour but d'afficher les données d'une table de tri connectée."
              ghLink="https://github.com/NathanVNT/Table-De-Tri-Connectee"
              demoLink="https://table-de-tri-connectee.vercel.app"
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
