import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import myImg from "../../Assets/avatar.png";
import Tilt from "react-parallax-tilt";
import {
  AiFillGithub,
  AiOutlineTwitter,
  AiFillInstagram,
} from "react-icons/ai";
import { FaLinkedinIn } from "react-icons/fa";
import { MdEmail } from "react-icons/md";

function Home2() {
  return (
    <Container fluid className="home-about-section" id="about">
      <Container>
        <Row>
          <Col md={8} className="home-about-description">
            <h1 style={{ fontSize: "2.6em" }}>
              LAISSEZ MOI ME<span className="purple"> PRÉSENTER</span> 
            </h1>
            <p className="home-about-body">
              Depuis mon plus jeune âge passionné par l'informatique, j'ai décidé d'en faire mon métier 🤓
              <br />
              <br />Je développe le plus souvent en
              <i>
                <b className="purple"> Java</b>
              </i>
              </p>
              </Col>
          <Col md={4} className="myAvtar">
            <Tilt>
              <img src={myImg} className="img-fluid" alt="avatar" />
            </Tilt>
          </Col>
        </Row>
        <Row>
          <Col md={12} className="home-about-social">
            <h1>RETROUVEZ MOI JUSTE ICI:</h1>
            <ul className="home-about-social-links">
              <li className="social-icons">
                <a
                  href="https://github.com/nathanvnt"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiFillGithub />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.linkedin.com/in/nathanvernet/"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <FaLinkedinIn />
                </a>
              </li>
            </ul>
            <br/>
          <a href="mailto:contact@nathanvernet.fr" className="fs-4" style={{color:'white'}}>contact@nathanvernet.fr</a>
          </Col>
        </Row>          
      </Container>
    </Container>
  );
}
export default Home2;
