import React from "react";
import Card from "react-bootstrap/Card";
import { ImPointRight } from "react-icons/im";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
            Bonjour! Je suis <span className="purple">Nathan VERNET </span>
            j'habite actuellement en<span className="purple"> France.</span>
            <br />
            Je suis actuellement à la recherche de missions freelance ainsi que d'une alternance            
            <br />
            Je suis tout fraichement diplômé d'un BTS Système Numérique option Informatique et Réseaux
            <br />
            <br />
            J'ai bien évidemment d'autres passions tels que
          </p>
          <ul>
            <li className="about-activity">
              <ImPointRight /> Voyager
            </li>
            <li className="about-activity">
              <ImPointRight /> L'audiovisuel
            </li>
            <li className="about-activity">
              <ImPointRight /> Le VTT
            </li>
          </ul>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
